<template>
  <v-card>
    <v-card-title class="text-h6" v-html="title"></v-card-title>
    <v-card-text v-html="description"></v-card-text>

    <v-card-text>
      <v-text-field v-model="code" class="c-input-xs" @keyup.enter="confirm"
                    :label="label" :placeholder="placeholder" autofocus
                    dense single-line outlined clearable hide-details></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        Hủy
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        Xác nhận
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ConfirmRemoveBox",
  props: {
    title: {
      type: String,
      default: () => 'Xác nhận'
    },
    description: {
      type: String,
      default: () => 'Bạn có chắc chắn?'
    },
    label: {
      type: String,
      default: () => 'Mã xác nhận'
    },
    placeholder: {
      type: String,
      default: () => 'Nhập lại mã để xác nhận'
    },
    codes: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    confirm_code: null,
    code: null,
  }),
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      if (!this.code) {
        this.$vToastify.error(`Chưa nhập ${this.label}`);
        this.$root.$emit('playErrorAudio')
        return false
      }
      if (!this.confirm_code) {
        if (this.codes.includes(this.code)) {
          this.code = null
          this.$vToastify.error(`Chưa nhập ${this.label}`);
          this.$root.$emit('playErrorAudio')
          return false
        }
        this.confirm_code = this.code
        this.code = null
        this.$root.$emit('playSuccessAudio')
        return false
      }
      if (!this.codes.includes(this.code)) {
        this.code = null
        this.$vToastify.error(`Mã xác nhận chưa đúng`);
        this.$root.$emit('playErrorAudio')
        return false
      }
      this.$emit('confirm', this.confirm_code)
      this.cancel()
    },
  }
}
</script>

<style scoped>

</style>